textarea:focus,
input:focus {
  outline: none;
}

::placeholder {
  font-style: italic;
  font-family: -apple-system, ".SFNSText-Regular", "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: 400;
}

/*Set default browser margins for headings to zero*/
h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  appearance: none;
}


.scrollbar::-webkit-scrollbar-track { background-color: var(--grey-5);}
.scrollbar::-webkit-scrollbar-thumb { background-color: var(--grey-5);}
.scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: var(--grey-5);
}
/* For firefox */
.scrollbar {
  scrollbar-color: var(--grey-5) var(--grey-5);
  scrollbar-width: thin;
}

.scrollbar-h::-webkit-scrollbar-track { background-color: var(--grey-5);}
.scrollbar-h::-webkit-scrollbar-thumb { background-color: var(--grey-5);}
.scrollbar-h::-webkit-scrollbar {
  height: 3px;
  background-color: var(--grey-5);
}

a {
  text-decoration: none;
}

html {
  -webkit-font-smoothing: antialiased;
}

